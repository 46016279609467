<template>
    <div class="container">
        <div class="row">
            <div class="col-12 py-4">
                <UserList />
            </div>
        </div>
    </div>
</template>
<script>
import UserList from '../components/UserList.vue';

export default {
    name: 'AccessControl',
    components:{ 
        UserList
    }
};
</script>
  