<template>
    <div class="card">
        <DataTable 
            :value="users" 
            :loading="loading" 
            responsive-layout="scroll" 
            show-gridlines 
            :paginator="true"
            
            :rows="rowsPagValue" 
            :rows-per-page-options="[10, 20, 50]" 
            :row-hover="true"
            :first="firstPagValue" 
            
            @row-click="open"
            removable-sort 
            dataKey="id"
            paginator-template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
            v-model:filters="filters"
            filterDisplay="menu"
            :sort-field="sortFieldValue"
            :sort-order="sortOrderValue"
            @sort="onSort($event)"
            @page="onSort($event)"
        >
            <template #header>
                <div style="display:flex; justify-content: space-between; ">
                    <h5 class="mb-2 md:m-0 p-as-md-center"> Access Control </h5>
                </div>

                <div class="table-header row gy-4 d-flex flex-row justify-content-between">
                    <div class="col-sm-12 col-md-2 d-flex flex-row justify-content-md-start">
                        <Button 
                            type="button" 
                            icon="pi pi-plus" 
                            label="Add new User" 
                            class="p-button-outlined me-4"
                            @click="addUser()" 
                        />
                    </div>

                    <div class="col-sm-12 col-md-10" >
                        <div class="d-flex flex-row justify-content-md-end" >
                            <Dropdown
                                v-model="roles"
                                :options="['admin']"
                                class="p-button-outlined me-4 w-25"
                                placeholder="Roles"
                                style="width: 70%"
                            />
                            <Button 
                                type="button" 
                                icon="pi pi-filter-slash" 
                                label="Clear" 
                                class="p-button-outlined me-2 "
                                @click="clearFilter()" 
                            />
                            <span class="p-input-icon-left me-4" style="position: relative;">
                                <i class="pi pi-search"  style="position: absolute; left: 5%; top:50%" />
                                <InputText 
                                    v-model="filters['global'].value" 
                                    placeholder="Keyword Search" 
                                    class="keywordSearch"
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </template>

            <!-- name -->
            <Column
                v-for="col of columns"
                :field="col.field"
                :header="col.header"
                :key="col.field"
                :sortable="true"
            > 
                <template #body="slotProps">
                    <div
                        :style="{'width': '100%', 'height': '100%', 'cursor': 'pointer'}"
                    >
                        {{ slotProps.data.firstName }}
                    </div>
                </template>
            </Column>

            <!-- Lastname -->
            <Column
                :header="'Last Name'"
                :key="'Last Name'"
                body-style="text-align: left"
            >
                <template #body="slotProps">
                    <div :style="{ 'width': '100%', 'height': '100%', 'cursor': 'pointer' }">
                        {{slotProps.data.lastName}}
                    </div>
                </template>

            </Column>

            <!-- Email -->
            <Column
                :header="'Email'"
                :key="'Email'"
                body-style="text-align: left"
            >
                <template #body="slotProps">
                    <div :style="{ 'width': '100%', 'height': '100%', 'cursor': 'pointer' }">
                        {{slotProps.data.email}}
                    </div>
                </template>
            </Column>

            <!-- Topic Access -->
            <Column 
                :header="'Topic Access'"
                :key="'Topic Access'"
                body-style="text-align: right"
                
            >
                <template #body="slotProps">
                    <div :style="{ 'width': '100%', 'height': '100%', 'cursor': 'pointer' }">
                        {{ slotProps.data.topicsCount }}
                    </div>
                </template>
            </Column>
            <!-- soft delete remove User  -->
            <Column :exportable="false" style="min-width: 5rem">
                <template #body="slotProps">
                <div style="display: grid; place-items: center;">
                    <Button
                    icon="pi pi-trash"
                    class="p-button-rounded p-button-danger"
                    @click="openDeleteUserDialog(slotProps.data)"
                    />
                </div>
                </template>
            </Column>

        </DataTable>
    </div>

    <!-- Delete Document -->
    <Dialog
        v-model:visible="deleteUserDialog"
        :style="{ width: '450px' }"
        header="Delete Document"
        :modal="true"
    >
        <div class="confirmation-content d-flex flex-row">
        <i class="pi pi-exclamation-triangle me-3" style="font-size: 2rem" />
        <span
            >Are you sure you want to delete
            <span class="fw-bold">{{ selectedRowUser.userName }}</span>
        </span>
        </div>
        <template #footer>
        <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text"
            @click="closeDialog()"
        />
        <Button
            label="Yes"
            icon="pi pi-check"
            class="p-button-text"
            :loading="deleteUserIsLoading"
            :disabled="deleteUserIsLoading"
            @click="deleteUser()"
        />
        </template>
    </Dialog>
</template>
<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
// import InputSwitch from 'primevue/inputswitch';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import useVuelidate from '@vuelidate/core';

import { useToast } from 'vue-toastification';
const  toast = useToast();

export default {
    name: 'UserList',
    components: {
        DataTable,
        Column,
        // InputSwitch,
        Dropdown,
        Button,
        InputText,
        Dialog,
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            loading: false,
            deleteUserIsLoading: false,
            filters: null,
            analistUser: true,
            roles:null,
            deleteUserDialog:false,
            selectedRowUser:null, 
            //Sort
            sortFieldValue:null,
            sortOrderValue:null,
            //Pagination
            firstPagValue: null,
            rowsPagValue:null, 
        }
    },
    created() {
        this.columns = [{ field: 'userName', header: 'Name' }];
        this.initFilters();
    },

    async mounted() {
        await this.getUsers();
        this.setAccessControlUserSort();
    },

    computed: {
        users(){
            return this.$store.getters.obtainUsersData;
        },
        accessControlUserSort(){
            return this.$store.getters.obtainAccessControlUserSort;
        }
    },
    methods: {
        async getUsers(){
            try {
                this.loading= true;
                await this.$store.dispatch('obtainUsersData');
                toast.success('Users obtained successfully');
                this.loading= false;
            } catch (error) {
                toast.error(error.message);
                this.loading = false
            }
        },
        setAccessControlUserSort(){
            try {
                //sort
                this.sortFieldValue = this.accessControlUserSort.sortField;
                this.sortOrderValue = this.accessControlUserSort.sortOrder;

               //pag
                if(this.accessControlUserSort.firstPagValue === null){
                this.firstPagValue = 0;
                }
                else{
                this.firstPagValue = this.accessControlUserSort.firstPagValue;
                }
                if(this.accessControlUserSort.rowsPagValue === null){
                this.rowsPagValue = 20;
                }
                else{
                this.rowsPagValue = this.accessControlUserSort.rowsPagValue
                }

                //applicationsFilters

            } catch (error) {
                console.log(error);
            }
        },
        open(event){
            this.$router.push(`./access/user/${event.data.id}`)
        },
        openDeleteUserDialog(user){
            this.deleteUserDialog = true;
            this.selectedRowUser = user;
        },

        async deleteUser(){
            try {
                this.deleteUserIsLoading = true; 
                await this.$store.dispatch('removeUser', this.selectedRowUser.id)
                this.deleteUserIsLoading = false;
                this.closeDialog();
            } catch (error) {
                this.deleteUserIsLoading = false
            }
        },
        closeDialog() {
            this.deleteUserDialog = false;
        },
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                name: {
                    operator: FilterOperator.AND,
                    constraints: [
                        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                    ],
                },
            };
        },
        clearFilter() {
            this.initFilters();
        },
        addUser(){
            this.$router.push(`./access/newUser`)
        },

        onSort(e){
            const accessControlUserSort = {
                sortField : e.sortField,
                sortOrder : e.sortOrder, 

                firstPagValue: e.first,
                rowsPagValue: e.rows,

            } 
            this.$store.dispatch('updateAccessControlUserSort', accessControlUserSort);
        }
    }
}
</script>

<style scoped>
.keywordSearch{
  padding: 0.2rem 0 0.2rem 2.5rem  !important;
}
</style>